import image from "../../../mepic.png";
import { useTheme } from "../../../contextApi/themeContext";
import { useNavigate } from "react-router-dom";
import { useTransition, animated } from "@react-spring/web";
import { useState } from "react";
const ChatGpt = () => {
  const navigate = useNavigate();
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const { theme } = useTheme();
  // Transition for image to video
  const transitions = useTransition(isVideoVisible, {
    from: { opacity: 0, transform: "translateX(100px)" },
    enter: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 800 },
  });

  const handleImageClick = () => {
    setIsVideoVisible((prev) => !prev);
  };
  return (
    <>
      <div className="w-full space-y-8">
        <div className="w-full space-y-1">
          <img className="rounded-xl mx-auto w-40" src={image} alt="me" />
        </div>
        {transitions((style, item) =>
          !item ? (
            <animated.div
              className="relative mx-auto w-[70vw] cursor-pointer group"
              style={style}
              onClick={handleImageClick}
            >
              <div className="relative mx-auto w-[70vw] h-[70vh] rounded-lg bg-black">
                <div
                  className="absolute inset-0 bg-cover bg-center duration-300"
                  style={{ backgroundImage: `url(/chatgpt.jpg)`, opacity: 0.3 }}
                ></div>

                {/* Text content for large screens */}
                <div className="hidden lg:flex lg:flex-col lg:absolute lg:-bottom-10 lg:left-0 lg:w-full lg:p-4">
                  <h2 className="text-3xl text-white font-bold tracking-widest">
                    MeBookMeta AI
                  </h2>
                  <p className="text-lg text-white font-bold tracking-widest">
                    MeBookMeta Bleeding Edge Technologies
                  </p>
                  <p className="text-lg text-white tracking-widest">
                    With our newest and best AI Features, you can elevate your
                    work to new heights! Our advanced search capabilities will
                    allow you to find the most relevant content and
                    collaborators with ease, while language translation tools
                    will enable seamless communication across borders and
                    cultures. We will always seek out and create cutting-edge
                    user tools to ensure your promotions are both persuasive and
                    effective.
                  </p>
                </div>

                {/* Text content for small screens */}
              </div>

              {/* Dark overlay with opacity transition */}
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                <span className="text-white text-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  Click to watch video
                </span>
              </div>
            </animated.div>
          ) : (
            <animated.div
              className="relative mx-auto w-[70vw] h-[70vh] cursor-pointer group"
              style={style}
            >
              <video
                src="https://mebook-work-v2.s3.us-east-2.amazonaws.com/1725645833085-The+Power+of+Ai.mov"
                controls
                autoPlay
                controlsList="nodownload"
                className="mx-auto w-[70vw] h-[70vh] rounded-lg"
                style={style}
              />

              {/* Back button placed in the top-right corner */}
              <div
                onClick={handleImageClick}
                className="absolute top-0 right-0 m-4 bg-black bg-opacity-60 hover:bg-opacity-80 transition-opacity duration-300 rounded-full p-2 cursor-pointer"
              >
                <span className="text-white text-sm">Back</span>
              </div>
            </animated.div>
          )
        )}
        <div className="block lg:hidden text-center p-4">
          <h2 className="text-3xl font-bold tracking-widest">MeBookMeta AI</h2>
          <p className="text-lg font-bold tracking-widest">
            MeBookMeta Bleeding Edge Technologies
          </p>
          <p className="text-lg tracking-widest">
            With our newest and best AI Features, you can elevate your work to
            new heights! Our advanced search capabilities will allow you to find
            the most relevant content and collaborators with ease, while
            language translation tools will enable seamless communication across
            borders and cultures. We will always seek out and create
            cutting-edge user tools to ensure your promotions are both
            persuasive and effective.
          </p>
        </div>
      </div>
    </>
  );
};

export default ChatGpt;
