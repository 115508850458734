import React, { useState, useEffect } from "react";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);

  useEffect(() => {
    const handleGlobalError = (message, source, lineno, colno, error) => {
      setHasError(true);
      const errorMessage =
        typeof message === "string" ? message : "An unexpected error occurred.";
      setErrorDetails({ message: errorMessage });
      return true; // Suppress overlay
    };

    const handlePromiseRejection = (event) => {
      const errorMessage =
        event.reason?.message || "An unhandled rejection occurred.";
      setHasError(true);
      setErrorDetails({ message: errorMessage });
      event.preventDefault(); // Suppress overlay
    };

    // Override console.error to suppress React overlay
    const originalConsoleError = console.error;
    console.error = function (...args) {
      if (
        args[0] &&
        typeof args[0] === "string" &&
        args[0].includes("Uncaught Error")
      ) {
        return; // Suppress runtime errors in the overlay
      }
      originalConsoleError(...args); // Call original console.error
    };

    window.onerror = handleGlobalError;
    window.addEventListener("unhandledrejection", handlePromiseRejection);

    return () => {
      window.onerror = null;
      window.removeEventListener("unhandledrejection", handlePromiseRejection);
      console.error = originalConsoleError; // Restore console.error
    };
  }, []);

  if (hasError) {
    return (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h2>Something went wrong.</h2>
        {errorDetails?.message && typeof errorDetails.message === "string" && (
          <p>Error: {errorDetails.message}</p>
        )}
        <button onClick={() => window.location.reload()}>Reload</button>
      </div>
    );
  }

  return <>{children}</>;
};

export default ErrorBoundary;
