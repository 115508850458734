import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import GlobalProgress from "./assets/GlobalProgress";
import ChatBox from "./Component new/ChatBox/ChatBox";
import { io } from "socket.io-client";
import { SERVER } from "./server/server";
import WVSocial from "./Component new/userWork/workViewSocial/WVSocial.jsx";
import { useSelector } from "react-redux";
// const LazyLandingPage = lazy(() => import("./pages/landingPage/LandingPage.jsx"))
import NewAskAI from "./components/AI/AskAI/NewAskAI.jsx"
import { routeObject } from "./route.js";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import PlayerIcon from "./Component new/widget/music/PlayerIcon.jsx";
import ErrorBoundary from "./ErrorBoundary.jsx";
export const SOCKET = io(SERVER);

function App() {
 
  const handleBeforeUnload = React.useCallback(() => {
    sessionStorage.removeItem('landing_count');
  },[]);
  
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="App">
      <ErrorBoundary>
        <PlayerIcon/>
        <ChatBox />

        {/* <Support /> */}
        <ToastContainer limit={1} />
        <GlobalProgress />
        <WVSocial/>
            <Routes>
              {
                routeObject.map(({path,component,children})=>{
                  if(children.length){
                  return <Route path={path}  element={component}>
                    {
                      children.map(({path,index,component})=>{
                        return index? <Route index element={component} /> :
                        <Route path={path} element={component} />
                      })
                    }
                  </Route>
                  }else{
                    return <Route path={path} element={component} />
                  }

                })
              }
              

            </Routes>
          </ErrorBoundary>
    </div>
  );
}
export default App;

