import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import { suggestionCategoryItem } from "../../../../Component new/User Dashboard/sideBarItem/SideBar";
import { Link } from "react-scroll";
import image from "../../../../mepic.png";
import { useDispatch, useSelector } from "react-redux";
import { callPopUp, homeSearch } from "../../../../Action";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from "react";
import MeCircularProgress from "../../../../components/componentsC/meCircularProgress/MeCircularProgress";
import { useTheme } from "../../../../contextApi/themeContext";
const pages = [
  { text: "Good to Know", link: "/research", to: "#" },
  { text: "Works", link: "", to: "trendingWork" },
];
export const searchCat = [
  {
    title: "Author",
    checked: false,
  },
  {
    title: "Writer",
    checked: false,
  },
  {
    title: "Music",
    checked: false,
  },
  {
    title: "Audio",
    checked: false,
  },
  {
    title: "Music Video",
    checked: false,
  },
  {
    title: "Film",
    checked: false,
  },
  {
    title: "Television",
    checked: false,
  },
  {
    title: "Print or Media",
    checked: false,
  },
  {
    title: "Visual Arts",
    checked: false,
  },
  {
    title: "Performing Arts",
    checked: false,
  },
];

const LandingNavNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [show, setShow] = useState(false);
  const [string, setString] = useState("");
  const [searchRoles, setSearchRoles] = useState(searchCat);
  const STORE = useSelector((state) => state?.getHomeSearch);
  const { theme } = useTheme();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onChangeHandle = (e) => {
    setString(e.target.value);
    // dispatch(homeSearch(string, { searchRoles: searchRoles.filter((item)=>item.checked).map((item)=>item.title)}))
  };

  const handleCheckboxToggle = (i) => {
    setSearchRoles((prevCheckboxes) =>
      prevCheckboxes.map((checkbox, index) =>
        index === i ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
    if (string) {
      // console.log('runnnnnnnnnn 111')
      // dispatch(homeSearch(string, { searchRoles: searchRoles.filter((item)=>item.checked).map((item)=>item.title)}))
    }
  };
  // console.log(string)

  useEffect(() => {
    // console.log('runnnnnnnnnnnnnnnnnn 117')
    if (string) {
      // console.log('runnnnnnnnnnnnnnnnnn 119')
      dispatch(
        homeSearch(string, {
          searchRoles: searchRoles
            .filter((item) => item.checked)
            .map((item) => item.title),
        })
      );
    }
  }, [string, searchRoles]);

  const closeSearchPanle = () => {
    // dispatch({ type: 'FAILED_HOME_SEARCH' })
    // dispatch(homeSearch('a', { searchRoles: [] }))
    setShow(false);
  };

  const themeTextColor = theme === "dark" ? "white" : "black";
  const bgTheme = theme === "dark" ? "black" : "white";
  return (
    <AppBar
      position="static"
      sx={{
        background: bgTheme,
        maxHeight: "8rem",
        boxShadow: 3,
        borderBottom: "1px solid #ccc",
      }}
    >
      <div sx={{ position: "relative" }}>
        <Toolbar
          disableGutters
          className="d-flex justify-content-center align-items-center"
        >
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <img
              src={image}
              alt="/"
              style={{ width: "5rem", height: "40px" }}
            />
          </Box>

          <Link
            to="topFirst"
            spy={false}
            smooth={true}
            sx={{ color: themeTextColor }}
          >
            <Typography
              component="a"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 500,
                letterSpacing: ".3rem",
                color: themeTextColor,
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              MeBookMeta
            </Typography>
          </Link>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: themeTextColor }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((item, idx) => (
                <MenuItem
                  key={idx}
                  onClick={
                    item.text !== "Works"
                      ? () => navigate(item.link)
                      : handleOpenUserMenu
                  }
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontWeight: 700,
                      letterSpacing: ".2rem",
                      color: "black",
                    }}
                  >
                    {item.text}
                  </Typography>
                </MenuItem>
              ))}
              {/* dispatch(callPopUp({ type: "SponsershipForm" })) */}
              <MenuItem
                onClick={() => {
                  dispatch(callPopUp({ type: "SponsershipForm" }));
                  handleCloseNavMenu();
                }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    fontWeight: 700,
                    letterSpacing: ".2rem",
                    color: "black",
                  }}
                >{`Sponsorships`}</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  dispatch(callPopUp({ type: "DonationForm" }));
                  handleCloseNavMenu();
                }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    fontWeight: 700,
                    letterSpacing: ".2rem",
                    color: "black",
                  }}
                >{`Donate`}</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box className="col-6">
            <input
              className="border px-4 py-2 lg:w-[400px] md:w-96 w-40 rounded-md outline-none"
              type="text"
              placeholder="Search"
              onChange={onChangeHandle}
              onClick={() => setShow(true)}
              style={{ color: "black" }}
            />
            {/* <TextField type='text' className='text_search_field' variant="standard" margin="normal" size="large" id="fullWidth" sx={{ width: '100%', outline: "none", background: "white", borderRadius: "3px", margin: "6px" }}  placeholder='Search User' /> */}
          </Box>
          {show && (
            <Box
              className="d-flex flex-column justify-content-between bg-light align-items-center"
              sx={{
                width: { xs: "100%", md: "800px" },
                zIndex: "99",
                position: "absolute",
                top: 70,
                maxHeight: "25rem",
                padding: "10px 10px",
                color: "black",
                borderRadius: "2px",
              }}
            >
              <CancelIcon
                onClick={closeSearchPanle}
                sx={{ marginLeft: "auto", cursor: "pointer" }}
              />
              <Box
                className=" d-flex p-2 m-2"
                sx={{
                  width: { xs: "350px", md: "800px" },
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box
                  className="p-2 bg-light"
                  sx={{
                    overflowY: "scroll",
                    height: { xs: "10rem", md: "20rem" },
                    width: { xs: "100%", md: "600px" },
                  }}
                >
                  {STORE?.loading ? (
                    <div className="col-12 d-flex align-items-center justify-content-center">
                      <MeCircularProgress />
                    </div>
                  ) : (
                    STORE?.users?.map((item, index) => (
                      <>
                        <div
                          className="col-12 d-flex  justify-content-between p-0"
                          style={{ border: "1px solid white" }}
                          onClick={() =>
                            navigate(`Profile/${item.name}/${item._id}`)
                          }
                          key={index + "mr"}
                        >
                          <div className="col-2  p-0 text-center">
                            <img
                              src={
                                item?.pdf
                                  ? item?.pdf
                                  : "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Photos.png"
                              }
                              alt="profile_img"
                              style={{
                                height: "2rem",
                                width: "2rem",
                                borderRadius: "50%",
                              }}
                            />
                          </div>
                          <div className="col-7 p-0 text-center">
                            <p>{item?.name}</p>
                          </div>
                          <div className="col-3  p-0 text-center">
                            <span
                              style={{
                                color: "blue",
                                fontSize: ".8rem",
                                cursor: "pointer",
                              }}
                            >
                              view profile
                            </span>
                          </div>
                        </div>
                      </>
                    ))
                  )}
                </Box>
                <Box
                  className="p-2  bg-light"
                  sx={{
                    overflowY: "auto",
                    height: { xs: "10rem", md: "20rem" },
                    width: { xs: "100%", md: "200px" },
                  }}
                >
                  <h6>Filter by categories</h6>
                  {searchRoles.map((item, idx) => (
                    <div
                      className="d-flex align-items-center p-1 m-0"
                      style={{ border: "1px solid white" }}
                      key={idx}
                    >
                      <input
                        className=" m-0"
                        type="checkbox"
                        style={{ width: "1rem", height: "1rem", padding: 0 }}
                        checked={item.checked}
                        onChange={() => handleCheckboxToggle(idx)}
                      />
                      <label className="p-1 m-0 ">{item.title}</label>
                    </div>
                  ))}
                </Box>
              </Box>
            </Box>
          )}

          <Box className=" flex items-center justify-center space-x-4 w-68 lg:flex hidden ">
            {pages.map((item, idx) => (
              <Button
                key={idx}
                variant="text"
                className="text-warp"
                onClick={
                  item.text !== "Works"
                    ? () => navigate(item.link)
                    : handleOpenUserMenu
                }
                sx={{
                  my: 2,
                  color: themeTextColor,
                  display: "block",
                  fontWeight: 600,
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          <Box className=" flex items-center justify-center w-32 ">
            <Tooltip title="Login your account">
              <Button
                variant="text"
                onClick={() => navigate("/signin")}
                sx={{
                  my: 2,
                  color: themeTextColor,
                  display: "block",
                  fontWeight: 600,
                  ml: 2,
                }}
              >
                Login
                <LoginIcon fontSize="28px" className="ml-2" />
              </Button>
            </Tooltip>
            {/* Search suggestions */}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {suggestionCategoryItem.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() =>
                    navigate(`/Search/${item.query}/${item.title}`)
                  }
                  sx={{}}
                >
                  <Typography textAlign="center">{item.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default LandingNavNew;
